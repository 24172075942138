module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"mobile":"(max-width: 767px)","tablet":"(max-width: 1023px)","portrait":"(min-width: 1024px)","desktop":"(min-width: 1200px)","bigDesktop":"(min-width: 1920px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-57TNQW9","cookieName":"gatsby-gdpr-google-tagmanager","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"gatsby"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VipClass.taxi","short_name":"VipClassTaxi Valencia","description":"Pide un taxi Tesla en Valencia.","start_url":"/","background_color":"#000","theme_color":"#000","display":"standalone","icon":"src/images/logo-pideteslavalencia.png","cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"],"workboxConfig":{"globPatterns":["**/icons/*"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
